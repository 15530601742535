import { useIntl } from "react-intl";
import React, { RefObject, FunctionComponent } from "react";

import { Container, Wrapper, Newsletter, NewsletterText } from "./styles";
import NewsletterForm from "../NewsletterForm";

interface IProps {
  white?: boolean;
  text?: string;
  reference?: RefObject<HTMLDivElement>;
}

const ContactSection: FunctionComponent<IProps> = props => {
  const { formatMessage } = useIntl();
  const { white, text } = props;

  return (
    <Container id="newsletter" ref={props?.reference}>
      <Wrapper>
        <Newsletter>
          <NewsletterText
            white={white}
            dangerouslySetInnerHTML={{
              __html: text
                ? text
                : formatMessage({ id: "pages.newsletter.text" })
            }}
          />
          <NewsletterForm />
        </Newsletter>
      </Wrapper>
    </Container>
  );
};

export default ContactSection;
