import { Container, Row, Col, Hidden } from "react-grid-system";
import { RouteComponentProps } from "@reach/router";
import React, { FunctionComponent } from "react";
import { graphql, navigate } from "gatsby";
import StickyBox from "react-sticky-box";
import { useIntl } from "react-intl";

import SideMenu, { SelectionOptions } from "../components/common/SideMenu";
import Preview from "../components/common/Preview";
import Layout from "../components/common/Layout";
import Newsletter from "../components/Newsletter";

interface NewsletterPageProps extends RouteComponentProps {
  data: {
    prismicImage: {
      data: {
        name: string;
        image: { alt: string; url: string };
      };
    };
    prismicNewsletterPage: {
      data: {
        text: {
          html: string;
        };
        title?: string;
        description?: string;
        keywords: string;
        canonical_url?: {
          url: string;
        };
        image?: {
          alt: string;
          url: string;
        };
      };
    };
  };
}

const NewsletterPage: FunctionComponent<NewsletterPageProps> = ({ data }) => {
  const { locale, formatMessage } = useIntl();

  const text = data.prismicNewsletterPage.data.text.html;

  const handleSideMenuClick = (input: SelectionOptions): void => {
    switch (input) {
      case SelectionOptions.SOLUTIONS:
        navigate(`/${locale}/#solutions`, {
          state: { skipSolutionsAnimation: true }
        });
        break;
      case SelectionOptions.SERVICES:
        navigate(`/${locale}/#services`, {
          state: { skipSolutionsAnimation: true }
        });
        break;
      case SelectionOptions.SUCCESSES:
        navigate(`/${locale}/#case-studies`, {
          state: { skipSolutionsAnimation: true }
        });
        break;
      case SelectionOptions.BLOG:
        navigate(`/${locale}/#blog`, {
          state: { skipSolutionsAnimation: true }
        });
        break;
    }
  };

  return (
    <Layout
      backgroundImageURL={data.prismicImage.data.image.url}
      metaTitle={
        data.prismicNewsletterPage.data.title ||
        formatMessage({ id: "pages.newsletter.meta-title" })
      }
      metaDescription={
        data.prismicNewsletterPage.data.description ||
        formatMessage({
          id: "pages.newsletter.meta-description"
        })
      }
      metaKeywords={
        data.prismicNewsletterPage.data.keywords ||
        formatMessage({
          id: "pages.newsletter.meta-keywords"
        })
      }
      metaImage={data.prismicNewsletterPage.data.image}
      metaURL={data.prismicNewsletterPage.data.canonical_url?.url}
    >
      <Container style={{ minHeight: "80vh" }}>
        <Row>
          <Hidden xs sm>
            <Col xs={3}>
              <StickyBox style={{ zIndex: 45 }}>
                <SideMenu white scrollhandler={handleSideMenuClick} />
              </StickyBox>
            </Col>
          </Hidden>
          <Col xs={12} md={9}>
            <Newsletter text={text} white />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!) {
    prismicImage(data: { name: { eq: "Contact Page Background" } }) {
      data {
        name
        image {
          alt
          url
        }
      }
    }
    prismicNewsletterPage(lang: { eq: $lang }) {
      data {
        text {
          html
        }
        title
        description
        keywords
        canonical_url {
          url
        }
        image {
          alt
          url
        }
      }
    }
  }
`;

export default Preview(NewsletterPage);
