import styled from "@emotion/styled";

export const Container = styled.div`
  margin-top: 100px;
  display: flex;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
export const Newsletter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const NewsletterText = styled.div<{ white?: boolean }>`
  display: flex;
  width: 80%;
  text-align: justify;
  flex-direction: column;
  margin-bottom: 32px;
  color: ${props => (props.white ? "white" : "black")};
  font-family: Roboto;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;
